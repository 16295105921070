/* helper vars, mixins etc. */


// vars
$xs-min: "(min-width:0)";
$xs-max: "(max-width:520px)";
$xs-sm:  "(min-width: 0) and (max-width:767px)";

$sm-min: "(min-width:768px)";
$sm-max: "(max-width:991px)";
$sm-md:  "(min-width: 768px) and (max-width:991px)";

$md-min: "(min-width:992px)";
$md-max: "(max-width:1199px)";
$md-lg:  "(min-width: 992px) and (max-width:1199px)";

$lg-min: "(min-width:1200px)";
$lg-max: "(max-width:none)";

// mixins
@mixin mobile {
	@media #{$xs-sm} {
		@content;
	}
}

@mixin tablet {
	@media #{$sm-md} {
		@content;
	}
}

@mixin desktop {
	@media #{$md-min} {
		@content;
	}
}

@mixin placeholder($color, $opacity) {
	&::-webkit-input-placeholder {
	    color: $color;
	    opacity: $opacity;
	}
	&:-moz-placeholder {
	   color: $color;
	   opacity: $opacity;
	}
	&::-moz-placeholder {
	   color: $color;
	   opacity: $opacity;
	}
	&:-ms-input-placeholder {
	   color: $color;
	   opacity: $opacity;
	}
	&:placeholder-shown {
	  color: $color;
	  opacity: $opacity;
	}
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
          border-radius: $radius;

      -ms-border-radius: $radius;
}

@mixin animation($property, $duration, $effect) {
  -webkit-transition: $property $duration $effect;
     -moz-transition: $property $duration $effect;
      -ms-transition: $property $duration $effect;
          transition: $property $duration $effect;
}

// styles
.clearfix {
	clear: both;
    display: block;
    height: 0;
    width: 100%;
}

.hidden {
	display: none;
}

.float-left {
	float :left;
}

.float-right {
	float :right;
}

.margin-auto {
	margin-left: auto;
	margin-right: auto;
}