/* owl */


// imports
@import '../helper';


.owl-nav {
	div {
		background: transparent;
		border-radius: 0;
		color: #fff;
		display: -webkit-flex;
		display:         flex;
		height: 100%;
		opacity: .4;
		position: absolute;
		text-shadow: 2px 0 2px rgba(0, 0, 0, .5);

		-webkit-align-items: center;
		        align-items: center;
		-webkit-justify-content: center;
		        justify-content: center;

		&:hover {
			opacity: .9;
		}
	}
	.owl-prev {
		left: 20px;
		top: -10px; 
	}
	.owl-next {
		right: 20px;
		top: -10px;
	}
}